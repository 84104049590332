<!--
 * @Description: 头部注释
 * @Author: v_Yangzhou
 * @Date: 2021-06-25 22:03:11
 * @LastEditors: v_zhouYang
 * @LastEditTime: 2021-06-27 18:14:11
-->
<!--  -->
<template>
  <div class="login">
    <div class="logo">
      <img src="../assets/web_logo.jpg" alt="" width="80px" height="80px"/>
    </div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="phone"
        name="手机号"
        label="手机号"
        placeholder="请输入手机号"
        :rules="[{ required: true, message: '' }]"
      ></van-field>
      <van-field
        v-model="sms"
        center
        clearable
        label="验证码"
        maxlength="6"
        placeholder="请输入短信验证码"
        :rules="[
          { required: true, message: '' },
          { validator: asyncValidator, message: '请输入6位验证码' },
        ]"
      >
        <template #button>
          <van-button
            size="small"
            type="info"
            :disabled="disabled"
            @click="sendSms"
            >{{ disabled ? time + "秒后重试" : "发送验证码" }}</van-button
          >
        </template>
      </van-field>
      <div style="margin: 25px">
        <van-button round block type="info" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      phone: "",
      sms: "",
      time: 60,
      disabled: false,
    };
  },
  methods: {
    asyncValidator(val) {
      return /\d{6}/.test(val);
    },
    sendSms() {
      if (this.isPoneAvailable(this.phone)) {
        this.disabled = true;
        this.http
          .get("/mp/user/sendCode", {
            params: {
              phone: this.phone,
              type: 1,
            },
          })
          .then(({ data }) => {
            if (data.code == 200) {
              this.$toast.success("验证码发送成功");
            }
          });
        let timer = setInterval(() => {
          if (this.time == 0) {
            this.time = 60;
            clearInterval(timer);
            this.disabled = false;
          }
          this.time--;
        }, 1000);
      } else {
        this.$toast.fail("请填写正确的手机号");
      }
    },
    onSubmit() {
      this.http
        .post("/mp/user/login", {
          userName: this.phone,
          captcha: this.sms,
          sendType:1
        })
        .then(({ data }) => {
          if (data.code == 200) {
            this.$toast.success("登陆成功");
            localStorage.setItem("sessionId", data.data.sessionId);
            this.$router.replace("/");
          }
        });
    },
    isPoneAvailable(telephone) {
      var myreg = /^1[0-9]{10}$/;
      if (!myreg.test(telephone)) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.login {
  height: 100%;
  position: relative;
  .van-form {
    width: 96%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo {
    margin-top: 180px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
